import React, { Component } from "react";
import axios from "axios";
import ScheduledGame from "./ScheduledGame";
import FinishedGame from "./FinishedGame";
import LiveGame from "./LiveGame";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { formatDateString } from "./Utils.js";

import "./Schedule.css";
export default class Game extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.data;
    this.state.cursor = "default";
  }

  componentDidMount() {
    this.updateData();
    this.interval = setInterval(() => {
      this.updateData.bind(this);
      //let now = new Date();
      this.updateData();
    }, 1000 * this.props.refresh);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  async updateData() {
    let rn = new Date();
    if (
      (rn > new Date(this.state.date) && this.state.status !== "post") ||
      !this.state.detailed
    ) {
      this.setState({ cursor: "progress" });
      let testingString = this.props.testing === true ? "&test=True" : "";
      axios
        .get("/rest/game?id=" + this.state.id + testingString)
        .then((response) => {
          if (response) {
            this.setState({
              detailed: response.data,
              cursor: "default",
            });
            let gameScore = response.data.gameScore;
            if (response.data.postGameScore !== undefined) {
              gameScore = response.data.postGameScore;
            } else if (response.data.inGameScore !== undefined) {
              gameScore = response.data.inGameScore;
            }
            this.props.update(gameScore);
            return response.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  render() {
    if (this.state.detailed) {
      if (this.state.detailed.situation === "Canceled") {
        return false;
      } else if (this.state.detailed.situation === "Scheduled") {
        return <ScheduledGame data={this.state}></ScheduledGame>;
      } else if (
        !this.props.testing &&
        this.state.detailed.situation === "Final"
      ) {
        return (
          <FinishedGame
            data={this.state}
            showWL={this.props.showWL}
          ></FinishedGame>
        );
      } else {
        return (
          <LiveGame
            cursor={this.state.cursor}
            data={this.state}
            refresh={() => this.updateData()}
          ></LiveGame>
        );
      }
    } else {
      let awayRanking = this.props.data.awayRanking
        ? "#" + this.props.data.awayRanking
        : "";
      let homeRanking = this.props.data.homeRanking
        ? "#" + this.props.data.homeRanking
        : "";
      let base = "https://a.espncdn.com/i/teamlogos/ncaa/500-dark/";
      let awayImg = base + this.props.data.awayID + ".png";
      let homeImg = base + this.props.data.homeID + ".png";
      return (
        <TableBody>
          <TableRow>
            <TableCell className="TopCell Channel FirstColumn">
              {this.state.broadcast}
            </TableCell>
            <TableCell className={"TopCell LogoCell"}>
              <img className="ScheduleLogo" src={awayImg} alt=""></img>
            </TableCell>
            <TableCell className="TopCell">
              {awayRanking} {this.state.awayTeam}
            </TableCell>
            <TableCell className="TopCell Date"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="BottomCell"></TableCell>
            <TableCell className="BottomCell LogoCell">
              <img className="ScheduleLogo" src={homeImg} alt=""></img>
            </TableCell>
            <TableCell className="BottomCell">
              {homeRanking} {this.state.homeTeam}
            </TableCell>
            <TableCell className="BottomCell Date">
              {formatDateString(this.state.date)}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
  }
}
