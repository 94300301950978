import React, { Component } from "react";
import { calculateGradient, rgbToHex } from "./Utils.js";
import { Link } from "react-router-dom";
import "./Schedule.css";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
/*var gameScoreTooltip =
  "Game Score: How interesting the current game is. Ranges from 0 to 1.";
var leverageTooltip =
  "Leverage Index: How important this sitation is compared to the average play from an average game. 1 is average.";
var epTooltip =
  "Expected Points: Expected Points resulting from this drive given the current situations";
var wpTooltip =
  "Home Win Probability: The win probability the home team wins given the current situation";
  */
export default class LiveGame extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 1000, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  render() {
    let gameScore = this.props.data.detailed.inGameScore
      ? this.props.data.detailed.inGameScore
      : this.props.data.detailed.gameScore;
    let rgb = calculateGradient(gameScore);
    let color = rgbToHex(rgb.R, rgb.G, rgb.B);
    let awayRanking = this.props.data.awayRanking
      ? "#" + this.props.data.awayRanking
      : "";
    let homeRanking = this.props.data.homeRanking
      ? "#" + this.props.data.homeRanking
      : "";
    let base = "https://a.espncdn.com/i/teamlogos/ncaa/500-dark/";
    let awayImg = base + this.props.data.awayID + ".png";
    let homeImg = base + this.props.data.homeID + ".png";
    let rivalry = this.props.data.detailed.rivalry;
    return (
      <TableBody style={this.state.cursor}>
        <TableRow>
          <Link
            className="HideLink WideLink"
            to={"/game/" + this.props.data.id}
          >
            <TableCell className="TopCell LiveScore Channel FirstColumn">
              {this.props.data.broadcast}
            </TableCell>
            <TableCell className={`TopCell LogoCell `}>
              <img className="ScheduleLogo" src={awayImg} alt=""></img>
            </TableCell>
            <TableCell
              className={`TopCell LiveScore ${rivalry ? "Rivalry" : ""}`}
            >
              <Link className="HideLink" to={"/team/" + this.props.data.awayID}>
                {awayRanking} {this.props.data.detailed.awayTeam}
              </Link>{" "}
            </TableCell>
            <TableCell className="TopCell LiveScore">
              {this.props.data.detailed.awayScore}
            </TableCell>
            <TableCell className="TopCell LiveScore">
              {" "}
              {this.props.data.detailed.ball === "away" ? "\u26AA" : ""}
            </TableCell>
            <TableCell className="TopCell LiveScore">
              {this.props.data.detailed.epa
                ? parseFloat(this.props.data.detailed.epa).toFixed(3)
                : ""}
            </TableCell>
          </Link>
        </TableRow>
        <TableRow>
          <Link
            className="HideLink WideLink"
            to={"/game/" + this.props.data.id}
          >
            <TableCell
              className="FirstColumn"
              style={{
                backgroundColor: color,
              }}
            >
              {parseFloat(gameScore).toFixed(3)}
            </TableCell>
            <TableCell className="LiveMiddle LogoCell">
              <img className="ScheduleLogo" src={homeImg} alt=""></img>
            </TableCell>
            <TableCell
              className={`LiveMiddle LiveScore ${rivalry ? "Rivalry" : ""}`}
            >
              <Link className="HideLink" to={"/team/" + this.props.data.homeID}>
                {homeRanking} {this.props.data.detailed.homeTeam}
              </Link>{" "}
            </TableCell>
            <TableCell className="LiveMiddle LiveScore">
              {this.props.data.detailed.homeScore}
            </TableCell>
            <TableCell className="LiveMiddle LiveScore">
              {" "}
              {this.props.data.detailed.ball === "home" ? "\u26AA" : ""}
            </TableCell>
            <TableCell className="LiveMiddle LiveScore">
              {this.props.data.detailed.winProbability
                ? parseFloat(
                    this.props.data.detailed.winProbability * 100
                  ).toFixed(2) + "%"
                : ""}
            </TableCell>
          </Link>
        </TableRow>

        <TableRow
          className={
            this.props.data.detailed.redzone
              ? "Redzone"
              : "BottomCell LiveBottomRow"
          }
          onClick={this.props.refresh}
        >
          <Link
            className="HideLink WideLink"
            to={"/game/" + this.props.data.id}
          >
            {this.props.data.detailed.situation !== "Halftime" && (
              <TableCell className="LiveBottom">
                <Link
                  className="HideLink WideLink"
                  to={"/game/" + this.props.data.id}
                >
                  {" "}
                  {this.props.data.detailed.period}Q:{" "}
                  {this.props.data.detailed.minutes}:
                  {this.props.data.detailed.seconds}
                </Link>
              </TableCell>
            )}
            <TableCell className="LiveBottom">
              {this.props.data.detailed.situation}
            </TableCell>
            <TableCell className="LiveBottom">
              {this.props.data.detailed.leverage
                ? parseFloat(this.props.data.detailed.leverage).toFixed(5)
                : ""}
            </TableCell>
          </Link>
        </TableRow>
      </TableBody>
    );
  }
}
