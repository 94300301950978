export function formatDateString(dateString) {
  let dt = new Date(dateString);
  return (
    dt.getMonth() +
    1 +
    "/" +
    dt.getDate() +
    " " +
    dt.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  );
}
export const Broadcasters = {
  ABC: 0,
  CBS: 1,
  FOX: 2,
  NBC: 3,
  ESPN: 4,
  ESPN2: 5,
  SECN: 6,
  "SECN Alt": 6.1,
  ACCN: 7,
  FS1: 8,
  BTN: 9,
  ESPNU: 10,
  FS2: 11,
  CBSSN: 12,
  ESPN3: 13,
  "ESPN+": 14,
  PAC12: 15,
  NONE: 100,
};
export function calculateGradientWhiteText(score) {
  let low = {
    R: 211,
    G: 47,
    B: 47,
  };
  let medium = {
    R: 251,
    G: 192,
    B: 45,
  };
  let high = {
    R: 56,
    G: 142,
    B: 60,
  };
  return calculateGradientBoth(score, low, medium, high);
}

function calculateGradientBoth(score, low, medium, high) {
  if (score === 0.5) {
    return medium;
  } else if (score < 0.5) {
    score = score * 2;
    return {
      R: Math.round((medium.R - low.R) * score + low.R),
      G: Math.round((medium.G - low.G) * score + low.G),
      B: Math.round((medium.B - low.B) * score + low.B),
    };
  } else {
    score = (score - 0.5) * 2;
    return {
      R: Math.round((high.R - medium.R) * score + medium.R),
      G: Math.round((high.G - medium.G) * score + medium.G),
      B: Math.round((high.B - medium.B) * score + medium.B),
    };
  }
}
export function calculateGradient(score) {
  let low = {
    R: 239,
    G: 154,
    B: 154,
  };
  let medium = {
    R: 255,
    G: 249,
    B: 196,
  };
  let high = {
    R: 165,
    G: 214,
    B: 167,
  };
  return calculateGradientBoth(score, low, medium, high);
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export function rgbToHexDict(d) {
  return rgbToHex(d.R, d.G, d.B);
}
export function cleanComment(commentBody) {
  return commentBody.replace("&amp;", "&").replace("&gt;", ">");
}
