import React, { Component } from "react";
import "./Schedule.css";
import axios from "axios";
import Game from "./Game";
import ButtonsHeader from "./ButtonsHeader";
import Table from "@material-ui/core/Table";
import { Broadcasters } from "./Utils";
class Schedule extends Component {
  constructor(props) {
    super(props);
    let week = "bowl";
    let now = new Date();
    for (let i = 0; i <= 16; i++) {
      let d = new Date(2024, 7, 29 + i * 7);
      //console.log(d);
      if (now < d) {
        week = i;
        break;
      }
    }
    if (this.props.week) {
      week = this.props.week;
    }
    this.state = {
      loaded: false,
      week: week,
      gameScores: {},
    };
    this.changeWeek = this.changeWeek.bind(this);
  }

  componentDidMount() {
    this.loadSchedule();
    this.interval = setInterval(() => {
      this.loadSchedule.bind(this);
      this.loadSchedule();
    }, 1000 * 60 * 5);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  updateGameScores(id, gameScore) {
    let gameScores = this.state.gameScores;
    if (gameScore === "N/A") {
      gameScores[id] = 0;
    } else {
      gameScores[id] = gameScore;
    }
    this.setState({ gameScores: gameScores });
  }
  async loadSchedule(week = this.state.week) {
    axios.get("/rest/schedule?week=" + week).then((response) => {
      let selected = this.state.selected;
      if (
        !selected ||
        (selected === "live" && response.data.liveGames.length === 0)
      ) {
        selected = "live";
        if (response.data.liveGames.length === 0) {
          if (week === "bowl") {
            let dt = new Date();
            let key = dt.getMonth() + 1 + "-" + dt.getDate();
            if (key in response.data) {
              selected = key;
            } else {
              selected = "top";
            }
          } else {
            selected = "top";
          }
        }
      } else if (this.state.week === "bowl" && week !== "bowl") {
        selected = "top";
      }
      if (selected === "top" && week === 0) {
        //check back next year
        selected = "all";
      }
      this.setState({
        schedule: response.data,
        loaded: true,
        selected: selected,
        week: week,
      });
    });
  }
  changeWeek(event) {
    this.props.history("?week=" + event.target.value);
    this.loadSchedule(event.target.value);
  }

  render() {
    let schedule = [];
    let selected = "";
    let sorted = false;
    if (this.state.loaded) {
      selected = this.state.selected;
      if (selected === "live") {
        schedule = this.state.schedule.liveGames;
      } else if (selected === "week") {
        schedule = this.state.schedule.weekGames;
        schedule.sort((a, b) => (a.date > b.date ? 1 : -1));
        sorted = true;
      } else if (selected === "noon") {
        schedule = this.state.schedule.noonGames;
      } else if (selected === "afternoon") {
        schedule = this.state.schedule.afternoonGames;
      } else if (selected === "evening") {
        schedule = this.state.schedule.eveningGames;
      } else if (selected === "night") {
        schedule = this.state.schedule.nightGames;
      } else if (selected === "sunmon") {
        schedule = this.state.schedule.sundayMondayGames;
      } else if (selected === "top") {
        schedule = [];
        let t25 = [];
        for (let dt in this.state.schedule) {
          if (dt === "liveGames") {
            continue;
          }
          schedule = schedule.concat(this.state.schedule[dt]);
        }
        for (let i in schedule) {
          let game = schedule[i];

          if (game.awayRanking < 25 || game.homeRanking < 25) {
            t25.push(game);
          }
        }
        schedule = t25;
        schedule.sort((a, b) => (a.date > b.date ? 1 : -1));
        sorted = true;
      } else if (selected === "all") {
        schedule = [];
        for (let dt in this.state.schedule) {
          if (dt === "liveGames") {
            continue;
          }
          schedule = schedule.concat(this.state.schedule[dt]);
        }
        schedule.sort((a, b) => (a.date > b.date ? 1 : -1));
        sorted = true;
      } else {
        schedule = this.state.schedule[selected];
        schedule.sort((a, b) => (a.date > b.date ? 1 : -1));
        sorted = true;
      }
      if (this.props.sort === "gamescore") {
        schedule.sort((a, b) =>
          this.state.gameScores[a.id] < this.state.gameScores[b.id] ? 1 : -1
        );
      } else if (this.props.sort === "tv" && !sorted) {
        schedule.sort((a, b) =>
          Broadcasters[a.broadcast] > Broadcasters[b.broadcast] ? 1 : -1
        );
      }
    }
    return (
      <div>
        {this.state.loaded && (
          <ButtonsHeader
            key={this.state.week}
            selected={this.state.selected}
            data={this.state.schedule}
            week={this.state.week}
            changeWeek={(e) => this.changeWeek(e)}
            changeSelected={(selected) => this.setState({ selected: selected })}
          />
        )}
        <div className="CenterDiv">
          <Table className="TableWidth900">
            {schedule.map((row) => (
              <Game
                data={row}
                key={row.id}
                refresh={this.props.refresh}
                testing={this.props.testing}
                update={(gs) => this.updateGameScores(row.id, gs)}
              />
            ))}
          </Table>
        </div>
      </div>
    );
  }
}

export default Schedule;
