import React, { useState, lazy, Suspense } from "react";
import Schedule from "./Schedule";
// import Boxscore from "./Boxscore";
import Header from "./Header";
// import Team from "./Team";
import Settings from "./Settings";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";

const Boxscore = lazy(() => import("./Boxscore"));
const Team = lazy(() => import("./Team"));
const GameDetail = lazy(() => import("./GameDetail"));
const Standings = lazy(() => import("./Standings"));
export default function App() {
  return (
    <Router>
      <Parent />
    </Router>
  );
}
function Parent() {
  const [refresh, setRefresh] = useState(localStorage.getItem("refresh") || 30);
  const [sort, setSort] = useState(localStorage.getItem("sort") || "tv");
  let query = new URLSearchParams(useLocation().search);
  let testing = false;
  let history = useHistory();
  function addHistory(link) {
    history.push(link);
  }
  return (
    <div className="ParentDiv">
      <div className="ContainerDiv">
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/boxscore/:id">
              <Header showBack={true} history={history} />
              <Boxscore />
            </Route>
            <Route path="/team/:teamID">
              <Header showBack={true} history={history} />
              <Team refresh={refresh} testing={testing} />
            </Route>
            <Route path="/settings">
              <Header showBack={true} history={history} />
              <Settings
                refresh={refresh}
                changeRefresh={(v) => setRefresh(v)}
              />
            </Route>
            <Route path="/game/:id">
              <Header showBack={true} history={history} />
              <GameDetail refresh={refresh} testing={testing} />
            </Route>
            <Route path="/standings/:conference">
              <Header showBack={true} history={history} />
              <Standings />
            </Route>
            <Route path="/">
              <Header
                showSort={true}
                sort={sort}
                changeSort={(v) => setSort(v)}
              />
              <Schedule
                week={query.get("week")}
                history={(h) => addHistory(h)}
                refresh={refresh}
                testing={testing}
                sort={sort}
              />
            </Route>
          </Switch>
        </Suspense>
      </div>
      {/* <div className="FooterDiv">
        <Link className="HideLink BlackText" to="/settings">
          <button className="TopButton">Settings</button>
        </Link>
      </div> */}
    </div>
  );
}
