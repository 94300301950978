import React, { Component } from "react";
import "./App.css";
import Button from "@material-ui/core/Button";

export default class ButtonsHeader extends Component {
  updateSelected(selected) {
    this.props.changeSelected(selected);
  }
  render() {
    if (this.props.week === "bowl") {
      let days = Object.keys(this.props.data);
      days.pop();
      days.sort((a, b) => {
        let aSplit = a.split("-");
        let bSplit = b.split("-");
        if (aSplit[0] > bSplit[0]) {
          return -1;
        } else if (aSplit[0] < bSplit[0]) {
          return 1;
        } else {
          return aSplit[1] - bSplit[1];
        }
      });
      return (
        <div className="ButtonDiv">
          {this.props.data.liveGames.length > 0 && (
            <Button
              className={`TopButton ${
                this.props.selected === "live" ? " SelectedButton" : ""
              }`}
              onClick={() => this.updateSelected("live")}
            >
              Live
            </Button>
          )}
          {this.props.data.liveGames.length === 0 && (
            <Button
              className={`TopButton ${
                this.props.selected === "top" ? " SelectedButton" : ""
              }`}
              onClick={() => this.updateSelected("top")}
            >
              Top
            </Button>
          )}
          <Button
            className={`TopButton ${
              this.props.selected === "all" ? " SelectedButton" : ""
            }`}
            onClick={() => this.updateSelected("all")}
          >
            All
          </Button>
          {days.map((week, index) => (
            <Button
              className={`TopButton ${
                this.props.selected === week ? " SelectedButton" : ""
              }`}
              onClick={() => this.updateSelected(week)}
              key={index}
            >
              {week}
            </Button>
          ))}
          <select
            defaultValue={this.props.week}
            onChange={this.props.changeWeek}
          >
            {[...Array(17).keys()].map((week) => (
              <option value={week} key={week}>
                {week}
              </option>
            ))}
            <option value="bowl" key="bowl">
              {"Bowl"}
            </option>
          </select>
        </div>
      );
    } else {
      return (
        <div className="ButtonDiv">
          <div className="SubButtonDiv">
            {this.props.data.liveGames.length > 0 && (
              <Button
                className={`TopButton ${
                  this.props.selected === "live" ? " SelectedButton" : ""
                }`}
                onClick={() => this.updateSelected("live")}
              >
                Live
              </Button>
            )}
            {this.props.data.liveGames.length === 0 && this.props.week > 0 && (
              <Button
                className={`TopButton ${
                  this.props.selected === "top" ? " SelectedButton" : ""
                }`}
                onClick={() => this.updateSelected("top")}
              >
                Top
              </Button>
            )}
            <Button
              className={`TopButton ${
                this.props.selected === "all" ? " SelectedButton" : ""
              }`}
              onClick={() => this.updateSelected("all")}
            >
              All
            </Button>
            {this.props.data.weekGames.length > 0 && (
              <Button
                className={`TopButton ${
                  this.props.selected === "week" ? " SelectedButton" : ""
                }`}
                onClick={() => this.updateSelected("week")}
              >
                Week
              </Button>
            )}
            {this.props.data.sundayMondayGames.length > 0 && (
              <Button
                className={`TopButton ${
                  this.props.selected === "sunmon" ? " SelectedButton" : ""
                }`}
                onClick={() => this.updateSelected("sunmon")}
              >
                SunMon
              </Button>
            )}
          </div>
          <div className="SubButtonDiv">
            {this.props.data.noonGames.length > 0 && (
              <Button
                className={`TopButton ${
                  this.props.selected === "noon" ? " SelectedButton" : ""
                }`}
                onClick={() => this.updateSelected("noon")}
              >
                Noon
              </Button>
            )}
            {this.props.data.afternoonGames.length > 0 && (
              <Button
                className={`TopButton ${
                  this.props.selected === "afternoon" ? " SelectedButton" : ""
                }`}
                onClick={() => this.updateSelected("afternoon")}
              >
                Afternoon
              </Button>
            )}
            {this.props.data.eveningGames.length > 0 && (
              <Button
                className={`TopButton ${
                  this.props.selected === "evening" ? " SelectedButton" : ""
                }`}
                onClick={() => this.updateSelected("evening")}
              >
                Evening
              </Button>
            )}
            {this.props.data.nightGames.length > 0 && (
              <Button
                className={`TopButton ${
                  this.props.selected === "night" ? " SelectedButton" : ""
                }`}
                onClick={() => this.updateSelected("night")}
              >
                Night
              </Button>
            )}
            <select
              defaultValue={this.props.week}
              onChange={this.props.changeWeek}
            >
              {[...Array(16).keys()].map((week) => (
                <option value={week} key={week}>
                  {week}
                </option>
              ))}
              <option value="bowl" key="bowl">
                {"Bowl"}
              </option>
            </select>
          </div>
        </div>
      );
    }
  }
}
