import React, { Component } from "react";
import { calculateGradient, rgbToHex } from "./Utils.js";
import { Link } from "react-router-dom";
import "./Schedule.css";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
export default class ScheduledGame extends Component {
  render() {
    //let gameScore = this.props.data.detailed.gameScore
    let gameScore = this.props.data.detailed.postGameScore;
    let rgb = calculateGradient(gameScore);
    let color = rgbToHex(rgb.R, rgb.G, rgb.B);
    let awayRanking = this.props.data.awayRanking
      ? "#" + this.props.data.awayRanking
      : "";
    let homeRanking = this.props.data.homeRanking
      ? "#" + this.props.data.homeRanking
      : "";
    let base = "https://a.espncdn.com/i/teamlogos/ncaa/500-dark/";
    let awayImg = base + this.props.data.awayID + ".png";
    let homeImg = base + this.props.data.homeID + ".png";
    let rivalry = this.props.data.detailed.rivalry;

    return (
      <TableBody>
        <TableRow>
          <TableCell className="TopCell Channel FirstColumn">
            {this.props.data.broadcast}
          </TableCell>
          <TableCell className="TopCell LogoCell">
            <img className="ScheduleLogo" src={awayImg} alt=""></img>
          </TableCell>
          <TableCell className={`TopCell ${rivalry ? "Rivalry" : ""}`}>
            <Link className="HideLink" to={"/team/" + this.props.data.awayID}>
              {awayRanking} {this.props.data.awayTeam}
            </Link>
          </TableCell>
          <TableCell
            className={
              "TopCell " +
              (this.props.showWL && !this.props.data.isHome
                ? this.props.data.result
                : "")
            }
          >
            {this.props.data.detailed.awayScore}
          </TableCell>
          <TableCell className="TopCell FinishedGameFinal">
            {this.props.data.detailed.situation}
          </TableCell>
          <TableCell className="TopCell FinishedGameBoxScore">
            <Link to={"/boxscore/" + this.props.data.id}>Box</Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className="BottomCell FirstColumn"
            style={{
              backgroundColor: color,
            }}
          >
            {parseFloat(gameScore).toFixed(3)}
          </TableCell>
          <TableCell className="BottomCell LogoCell">
            <img className="ScheduleLogo" src={homeImg} alt=""></img>
          </TableCell>
          <TableCell className={`BottomCell ${rivalry ? "Rivalry" : ""}`}>
            <Link className="HideLink" to={"/team/" + this.props.data.homeID}>
              {homeRanking} {this.props.data.homeTeam}
            </Link>
          </TableCell>
          <TableCell
            className={
              "BottomCell " +
              (this.props.showWL && this.props.data.isHome
                ? this.props.data.result
                : "")
            }
          >
            {this.props.data.detailed.homeScore}
          </TableCell>
          <TableCell className="BottomCell FinishedGameFinal">
            {this.props.data.detailed.winProbability
              ? parseFloat(
                  this.props.data.detailed.winProbability * 100
                ).toFixed(2) + "%"
              : ""}{" "}
          </TableCell>
          <TableCell className="BottomCell FinishedGameBoxScore">
            {parseFloat(this.props.data.detailed.excitement).toFixed(2)}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }
}
