import React, { Component } from "react";

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: this.props.refresh,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ refresh: event.target.value });
    localStorage.setItem("refresh", event.target.value);
    this.props.changeRefresh(event.target.value);
  }
  render() {
    return (
      <label>
        Refresh Rate:
        <input
          type="number"
          onChange={this.handleChange}
          value={this.state.refresh}
        ></input>
      </label>
    );
  }
}
