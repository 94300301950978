import React, { Component } from "react";
import { formatDateString, calculateGradient, rgbToHex } from "./Utils.js";
import { Link } from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import "./Schedule.css";

export default class ScheduledGame extends Component {
  render() {
    let rgb = calculateGradient(this.props.data.detailed.gameScore);
    let color = rgbToHex(rgb.R, rgb.G, rgb.B);
    let awayRanking = this.props.data.awayRanking
      ? "#" + this.props.data.awayRanking
      : "";
    let homeRanking = this.props.data.homeRanking
      ? "#" + this.props.data.homeRanking
      : "";
    let base = "https://a.espncdn.com/i/teamlogos/ncaa/500-dark/";
    let awayImg = base + this.props.data.awayID + ".png";
    let homeImg = base + this.props.data.homeID + ".png";
    let rivalry = this.props.data.detailed.rivalry;
    let gameScore = this.props.data.detailed.gameScore;
    return (
      <TableBody>
        <TableRow>
          <Link
            className="HideLink WideLink"
            to={"/game/" + this.props.data.id}
          >
            <TableCell className="TopCell Channel FirstColumn">
              {this.props.data.broadcast}
            </TableCell>
            <TableCell className="TopCell LogoCell">
              <img className="ScheduleLogo" src={awayImg} alt=""></img>
            </TableCell>
            <TableCell className={`TopCell ${rivalry ? "Rivalry" : ""}`}>
              <Link className="HideLink" to={"/team/" + this.props.data.awayID}>
                {awayRanking} {this.props.data.awayTeam}
              </Link>{" "}
            </TableCell>
            <TableCell className="TopCell Date">
              {formatDateString(this.props.data.date)}
            </TableCell>
          </Link>
        </TableRow>
        <TableRow>
          <Link
            className="HideLink WideLink"
            to={"/game/" + this.props.data.id}
          >
            <TableCell
              className="BottomCell FirstColumn"
              style={{
                backgroundColor: color,
              }}
            >
              {parseFloat(gameScore).toFixed(3)}
            </TableCell>
            <TableCell className="BottomCell LogoCell">
              <img className="ScheduleLogo" src={homeImg} alt=""></img>
            </TableCell>
            <TableCell className={`BottomCell ${rivalry ? "Rivalry" : ""}`}>
              <Link className="HideLink" to={"/team/" + this.props.data.homeID}>
                {homeRanking} {this.props.data.homeTeam}
              </Link>{" "}
            </TableCell>
            <TableCell className="BottomCell Date">
              {this.props.data.detailed.winProbability
                ? parseFloat(
                    this.props.data.detailed.winProbability * 100
                  ).toFixed(2) + "%"
                : ""}{" "}
            </TableCell>
          </Link>
        </TableRow>
      </TableBody>
    );
  }
}
