import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { MdSort } from "react-icons/md";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
export default function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sort, setSort] = React.useState(props.sort);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeSort = (s) => {
    setSort(s);
    localStorage.setItem("sort", s);
    props.changeSort(s);
    console.log(sort);
    handleClose();
  };
  return (
    <div className="HeaderDiv">
      {props.showBack && (
        <Button className="BackButton" onClick={() => props.history.goBack()}>
          Back
        </Button>
      )}
      <h1>
        <Link className="HideLink" to="/">
          CFB Central
        </Link>
      </h1>
      <div></div>
      {props.showSort && (
        <div>
          <MdSort size={40} className="SortButton" onClick={handleClick} />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className={sort === "tv" ? "SelectedMenuItem" : ""}
              onClick={() => changeSort("tv")}
            >
              TV Channel{" "}
            </MenuItem>
            <MenuItem
              className={sort === "gamescore" ? "SelectedMenuItem" : ""}
              onClick={() => changeSort("gamescore")}
            >
              Game Score
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
}
